import React, { useState, useEffect } from 'react';

const AdminDashboard = () => {
  const [data, setData] = useState([]);
  const [filterUserName, setFilterUserName] = useState('');
  const [filterFormType, setFilterFormType] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false); // สถานะการเข้าสู่ระบบ
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');

  useEffect(() => {
    if (isLoggedIn) {
      const fetchData = async () => {
        try {
          const response = await fetch('https://ptqtv2-api.appsecure.website/api/v1/fetch-data', {
            method: 'GET',
            headers: { 
              'Content-Type': 'application/json',
              'Authorization': 'Bearer cHRxdDo3TGVaLVl4Rk0yQ3VVVW50ZkhETQ==' 
            },
          });
          const result = await response.json();
          setData(result);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }
  }, [isLoggedIn]);

  const handleLogin = (e) => {
    e.preventDefault();
    // ตรวจสอบ username และ password (จำลองการตรวจสอบ)
    if (username === 'reportadmin' && password === 'V2V@wJwFbA@i@CpmYd') {
      setIsLoggedIn(true);
      setLoginError('');
    } else {
      setLoginError('Invalid username or password');
    }
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    setUsername('');
    setPassword('');
  };

  // ฟังก์ชันกรองข้อมูล
  const filteredData = data.filter((item) => {
    const matchesUserName =
      item.userName && item.userName.toLowerCase().includes(filterUserName.toLowerCase());
    const matchesFormType = filterFormType ? item.formType === filterFormType : true;
    return matchesUserName && matchesFormType;
  });

  // ฟังก์ชันสำหรับดาวน์โหลดข้อมูลเป็น CSV
  const handleDownloadCSV = () => {
    const csvData = convertToCSV(filteredData);

    // สร้าง Blob และดาวน์โหลดไฟล์
    const blob = new Blob(["\ufeff"+csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'filtered_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // ฟังก์ชันสำหรับแปลงข้อมูลเป็นรูปแบบ CSV
  const convertToCSV = (data) => {
    const headers = [
      'Form Type',
      'User Name',
      'Test Type',
      'Website URL',
      'Functions',
      'Target Sitemap',
      'Language',
      'LOC',
      'Coding Type',
      'Preferred Time',
      'Environment',
      'Contact Point',
      'App Info',
      'Users',
      'Roles',
      'API Endpoint',
      'Period',
      'Contact',
      'Target Domain',
      'User Type',
    ];

    const csvRows = [];
    csvRows.push(headers.join(','));

    data.forEach((item) => {
      const row = [
        item.formType || '',
        item.userName || '',
        item.type || '',
        item.websiteUrl || '',
        item.functions || '',
        item.targetSitemap || '',
        item.language || '',
        item.loc || '',
        item.codingType || '',
        item.preferredTime || item.period || '',
        item.environment || '',
        item.contactPoint || item.contact || '',
        item.appInfo || '',
        item.users || '',
        item.roles || '',
        item.apiEndpoint || '',
        item.period || '',
        item.contact || '',
        item.targetDomain || '',
        item.userType || '',
      ];

      const escapedRow = row.map((field) =>
        `"${String(field).replace(/"/g, '""').replace(/\n/g, ' ')}"`
      );
      csvRows.push(escapedRow.join(','));
    });

    return csvRows.join('\n');
  };

  // ถ้ายังไม่เข้าสู่ระบบ ให้แสดงหน้าเข้าสู่ระบบ
  if (!isLoggedIn) {
    return (
      <div className="bg-gray-100 flex items-center justify-center min-h-screen">
        <div className="bg-white p-4 md:p-8 rounded-lg shadow-md w-full max-w-md">
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Login</h2>
          {loginError && <p className="text-red-500 mb-4">{loginError}</p>}
          <form onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block text-gray-700">Username:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                className="border rounded px-2 py-1 w-full"
              />
            </div>
            <div className="mb-6">
              <label className="block text-gray-700">Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="border rounded px-2 py-1 w-full"
              />
            </div>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full"
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }

  // หากเข้าสู่ระบบแล้ว แสดงหน้า Admin Dashboard
  return (
    <div className="bg-gray-100 flex items-center justify-center min-h-screen">
      <div className="bg-white p-4 md:p-8 rounded-lg shadow-md w-full max-w-6xl">
        <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
          Admin Dashboard - Penetration Testing Data
        </h2>

        <div className="text-right mb-4">
          <button
            onClick={handleLogout}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
          >
            Logout
          </button>
        </div>

        {/* ส่วนกรองข้อมูล */}
        <div className="mb-6 flex flex-wrap items-center">
          <div className="mr-4 mb-2">
            <label className="mr-2 font-bold">Filter by User Name:</label>
            <input
              type="text"
              value={filterUserName}
              onChange={(e) => setFilterUserName(e.target.value)}
              className="border rounded px-2 py-1"
              placeholder="Enter User Name"
            />
          </div>
          <div className="mb-2">
            <label className="mr-2 font-bold">Filter by Form Type:</label>
            <select
              value={filterFormType}
              onChange={(e) => setFilterFormType(e.target.value)}
              className="border rounded px-2 py-1"
            >
              <option value="">All</option>
              <option value="website">Website</option>
              <option value="mobile">Mobile</option>
              <option value="infrastructure">Infrastructure</option>
            </select>
          </div>
        </div>

        {/* ปุ่มดาวน์โหลด CSV */}
        <div className="mb-6">
          <button
            onClick={handleDownloadCSV}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Download CSV
          </button>
        </div>

        {/* ส่วนแสดงผลข้อมูล Web Penetration Testing */}
        {(filterFormType === '' || filterFormType === 'website') && (
          <>
            <h3 className="text-xl font-bold text-gray-800 mb-4">Web Penetration Testing</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto mb-8">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="px-2 md:px-4 py-2">User Name</th>
                    <th className="px-2 md:px-4 py-2">Test Type</th>
                    <th className="px-2 md:px-4 py-2">Website URL</th>
                    <th className="px-2 md:px-4 py-2">Functions</th>
                    <th className="px-2 md:px-4 py-2">Target Sitemap</th>
                    <th className="px-2 md:px-4 py-2">Language</th>
                    <th className="px-2 md:px-4 py-2">LOC</th>
                    <th className="px-2 md:px-4 py-2">Coding Type</th>
                    <th className="px-2 md:px-4 py-2">Preferred Time</th>
                    <th className="px-2 md:px-4 py-2">Environment</th>
                    <th className="px-2 md:px-4 py-2">Contact Point</th>
                  </tr>
                </thead>
                <tbody className="bg-white text-center">
                  {filteredData
                    .filter((item) => item.formType === 'website')
                    .map((item, index) => (
                      <tr key={index} className="bg-gray-100 border-b">
                        <td className="px-2 md:px-4 py-2">{item.userName || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.type || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.websiteUrl || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.functions || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.targetSitemap || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.language || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.loc || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.codingType || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.preferredTime || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.environment || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.contact || 'N/A'}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {/* ส่วนแสดงผลข้อมูล Mobile Penetration Testing */}
        {(filterFormType === '' || filterFormType === 'mobile') && (
          <>
            <h3 className="text-xl font-bold text-gray-800 mb-4">Mobile Penetration Testing</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto mb-8">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="px-2 md:px-4 py-2">User Name</th>
                    <th className="px-2 md:px-4 py-2">Test Type</th>
                    <th className="px-2 md:px-4 py-2">App Info</th>
                    <th className="px-2 md:px-4 py-2">Users</th>
                    <th className="px-2 md:px-4 py-2">Roles</th>
                    <th className="px-2 md:px-4 py-2">Functions</th>
                    <th className="px-2 md:px-4 py-2">Language</th>
                    <th className="px-2 md:px-4 py-2">LOC</th>
                    <th className="px-2 md:px-4 py-2">API Endpoint</th>
                    <th className="px-2 md:px-4 py-2">Preferred Time</th>
                    <th className="px-2 md:px-4 py-2">Environment</th>
                    <th className="px-2 md:px-4 py-2">Contact Point</th>
                  </tr>
                </thead>
                <tbody className="bg-white text-center">
                  {filteredData
                    .filter((item) => item.formType === 'mobile')
                    .map((item, index) => (
                      <tr key={index} className="bg-gray-100 border-b">
                        <td className="px-2 md:px-4 py-2">{item.userName || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.type || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.appInfo || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.users || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.roles || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.functions || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.language || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.loc || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.apiEndpoint || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.preferredTime || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.environment || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.contact || 'N/A'}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {/* ส่วนแสดงผลข้อมูล Infrastructure Penetration Testing */}
        {(filterFormType === '' || filterFormType === 'infrastructure') && (
          <>
            <h3 className="text-xl font-bold text-gray-800 mb-4">Infrastructure Penetration Testing</h3>
            <div className="overflow-x-auto">
              <table className="min-w-full table-auto">
                <thead className="bg-gray-800 text-white">
                  <tr>
                    <th className="px-2 md:px-4 py-2">User Name</th>
                    <th className="px-2 md:px-4 py-2">Test Type</th>
                    <th className="px-2 md:px-4 py-2">Target Domain</th>
                    <th className="px-2 md:px-4 py-2">Functions</th>
                    <th className="px-2 md:px-4 py-2">User Type</th>
                    <th className="px-2 md:px-4 py-2">Preferred Time</th>
                    <th className="px-2 md:px-4 py-2">Environment</th>
                    <th className="px-2 md:px-4 py-2">Contact Point</th>
                  </tr>
                </thead>
                <tbody className="bg-white text-center">
                  {filteredData
                    .filter((item) => item.formType === 'infrastructure')
                    .map((item, index) => (
                      <tr key={index} className="bg-gray-100 border-b">
                        <td className="px-2 md:px-4 py-2">{item.userName || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.type || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.targetDomain || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.functions || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.userType || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.preferredTime || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.environment || 'N/A'}</td>
                        <td className="px-2 md:px-4 py-2">{item.contact || 'N/A'}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
