// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import WebsiteQuestionnaire from './components/WebsiteQuestionnaire';
import MobileQuestionnaire from './components/MobileQuestionnaire';
import InfraQuestionnaire from './components/InfraQuestionnaire';
import AdminDashboard from './components/AdminDashboard';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/website-questionnaire" element={<WebsiteQuestionnaire />} />
          <Route path="/mobile-questionnaire" element={<MobileQuestionnaire />} />
          <Route path="/infra-questionnaire" element={<InfraQuestionnaire />} />
          <Route path="/report" element={<AdminDashboard />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
