import React, { useState } from 'react';

const InfraQuestionnaire = () => {
    const [type, setTestType] = useState('');

    const handleTestTypeChange = (e) => {
        setTestType(e.target.value);
    };

    const submitFormData = async () => {
        const formDataObject = {
            userName: document.getElementById('user-name').value,
            formType: 'infrastructure',
            type
        };

        if (type === 'blackbox') {
            formDataObject.targetDomain = document.getElementById('target-domain').value;
            formDataObject.contact = document.getElementById('contact-point-blackbox').value;
        } else if (type === 'greybox') {
            formDataObject.targetDomain = document.getElementById('website-url-greybox').value;
            formDataObject.functions = document.getElementById('functions-greybox').value;
            formDataObject.preferredTime = document.getElementById('preferred-time-greybox').value;
            formDataObject.environment = document.getElementById('environment-greybox').value;
            formDataObject.contact = document.getElementById('contact-point-greybox').value;
        } else if (type === 'whitebox') {
            formDataObject.targetDomain = document.getElementById('website-url-whitebox').value;
            formDataObject.functions = document.getElementById('functions-whitebox').value;
            formDataObject.userType = document.getElementById('user-type-whitebox').value;
            formDataObject.preferredTime = document.getElementById('preferred-time-whitebox').value;
            formDataObject.environment = document.getElementById('environment-whitebox').value;
            formDataObject.contact = document.getElementById('contact-point-whitebox').value;
        }

        try {
            const response = await fetch('https://ptqtv2-api.appsecure.website/api/v1/submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 
                    'Authorization': 'Bearer cHRxdDo3TGVaLVl4Rk0yQ3VVVW50ZkhETQ==' },
                body: JSON.stringify(formDataObject)
            });

            if (response.ok) {
                alert('Form data submitted successfully');
                document.getElementById('pentest-form').reset();
            } else {
                alert('Failed to submit data');
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    return (
        <div className="bg-gray-100 flex items-center justify-center  h-full min-h-screen py-4">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg">
                <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                    Infrastructure Penetration Testing Questionnaire
                </h2>

                <form id="pentest-form">
                    <div className="mb-4">
                        <label htmlFor="user-name" className="block text-gray-700 font-medium mb-2">
                            กรุณากรอกชื่อบริษัทของท่าน (สามารถใช้ตัวย่อได้)
                        </label>
                        <input
                            type="text"
                            id="user-name"
                            name="user-name"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="test-type" className="block text-gray-700 font-medium mb-2">
                            ต้องการทำ Website Penetration Testing ลักษณะใด?
                        </label>
                        <select
                            id="test-type"
                            name="test-type"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={handleTestTypeChange}
                        >
                            <option value="" disabled selected>
                                กรุณาเลือก
                            </option>
                            <option value="blackbox">Black Box - ทดสอบเจาะจากมุมมองของ Hacker ภายนอก</option>
                            <option value="greybox">Grey Box - ทดสอบเจาะจากมุมมองผู้เชี่ยวชาญที่มีข้อมูลบางส่วน</option>
                            <option value="whitebox">White Box - ทดสอบจากมุมมองของผู้เชี่ยวชาญที่มีข้อมูลมาก</option>
                        </select>
                    </div>

                    {type === 'blackbox' && (
                        <div className="mb-6">
                            <label htmlFor="target-domain" className="block text-gray-700 font-medium mb-2">
                                จำนวน Target IP หรือ Website FQDN
                            </label>
                            <input
                                type="text"
                                id="target-domain"
                                name="target-domain"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="contact-point-blackbox" className="block text-gray-700 font-medium mb-2">
                                Contact point (ข้อมูลสำหรับติดต่อ)
                            </label>
                            <input
                                type="text"
                                id="contact-point-blackbox"
                                name="contact-point-blackbox"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    {type === 'greybox' && (
                        <div className="mb-6">
                            <label htmlFor="website-url-greybox" className="block text-gray-700 font-medium mb-2">
                                จำนวน Target IP หรือ Website FQDN
                            </label>
                            <input
                                type="text"
                                id="website-url-greybox"
                                name="website-url-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="functions-greybox" className="block text-gray-700 font-medium mb-2">
                                ทดสอบแบบ Internal network หรือ Internet?
                            </label>
                            <select
                                id="functions-greybox"
                                name="functions-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="" disabled selected>
                                    กรุณาเลือก
                                </option>
                                <option value="internet">Internet</option>
                                <option value="internal">Internal Network</option>
                            </select>

                            <label htmlFor="preferred-time-greybox" className="block text-gray-700 font-medium mb-2">
                                Pentest period time?
                            </label>
                            <select
                                id="preferred-time-greybox"
                                name="preferred-time-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Business hour">Business hour</option>
                                <option value="Non-Business hour">Non-Business hour</option>
                                <option value="Weekend">Weekend</option>
                            </select>

                            <label htmlFor="environment-greybox" className="block text-gray-700 font-medium mb-2">
                                Testing Environment?
                            </label>
                            <select
                                id="environment-greybox"
                                name="environment-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Non-Production">Test (UAT, Staging)</option>
                                <option value="Production">Production</option>
                            </select>

                            <label htmlFor="contact-point-greybox" className="block text-gray-700 font-medium mb-2">
                                Contact point
                            </label>
                            <input
                                type="text"
                                id="contact-point-greybox"
                                name="contact-point-greybox"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    {type === 'whitebox' && (
                        <div className="mb-6">
                            <label htmlFor="website-url-whitebox" className="block text-gray-700 font-medium mb-2">
                                จำนวน Target IP หรือ Website FQDN
                            </label>
                            <input
                                type="text"
                                id="website-url-whitebox"
                                name="website-url-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="functions-whitebox" className="block text-gray-700 font-medium mb-2">
                                ทดสอบแบบ Internal network หรือ Internet?
                            </label>
                            <select
                                id="functions-whitebox"
                                name="functions-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="" disabled selected>
                                    กรุณาเลือก
                                </option>
                                <option value="internet">Internet</option>
                                <option value="internal">Internal Network</option>
                            </select>

                            <label htmlFor="user-type-whitebox" className="block text-gray-700 font-medium mb-2">
                                ทดสอบในสิทธิ์ของผู้ใช้ประเภทใด?
                            </label>
                            <select
                                id="user-type-whitebox"
                                name="user-type-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="domainAdmin">Domain Admin</option>
                                <option value="domainUser">Domain User</option>
                                <option value="guestUser">Guest User</option>
                            </select>

                            <label htmlFor="preferred-time-whitebox" className="block text-gray-700 font-medium mb-2">
                                Pentest period time?
                            </label>
                            <select
                                id="preferred-time-whitebox"
                                name="preferred-time-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Business hour">Business hour</option>
                                <option value="Non-Business hour">Non-Business hour</option>
                                <option value="Weekend">Weekend</option>
                            </select>

                            <label htmlFor="environment-whitebox" className="block text-gray-700 font-medium mb-2">
                                Testing Environment?
                            </label>
                            <select
                                id="environment-whitebox"
                                name="environment-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Non-Production">Test (Staging, Test, UAT)</option>
                                <option value="Production">Production</option>
                            </select>

                            <label htmlFor="contact-point-whitebox" className="block text-gray-700 font-medium mb-2">
                                Contact point
                            </label>
                            <input
                                type="text"
                                id="contact-point-whitebox"
                                name="contact-point-whitebox"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    <button
                        type="button"
                        onClick={submitFormData}
                        className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default InfraQuestionnaire;
