import React, { useState } from 'react';

const WebsiteQuestionnaire = () => {
    const [testType, setTestType] = useState('');

    const handleTestTypeChange = (e) => {
        setTestType(e.target.value);
    };

    const submitFormData = async () => {
        const formData = new FormData(document.getElementById('pentest-form'));
        const userName = formData.get('user-name');
        let formDataObject = {};

        if (testType === 'blackbox') {
            formDataObject = {
                formType: 'website',
                userName: userName,
                type: testType,
                websiteUrl: formData.get('target-domain'),
                contact: formData.get('contact-point-blackbox')
            };
        } else if (testType === 'greybox') {
            formDataObject = {
                formType: 'website',
                userName: userName,
                type: testType,
                websiteUrl: formData.get('website-url-greybox'),
                functions: formData.get('functions-greybox'),
                targetSitemap: formData.get('target-url-greybox'),
                preferredTime: formData.get('preferred-time-greybox'),
                environment: formData.get('environment-greybox'),
                contact: formData.get('contact-point-greybox')
            };
        } else if (testType === 'whitebox') {
            formDataObject = {
                formType: 'website',
                userName: userName,
                type: testType,
                websiteUrl: formData.get('website-url-whitebox'),
                functions: formData.get('functions-whitebox'),
                targetSitemap: formData.get('target-url-whitebox'),
                language: formData.get('language'),
                loc: formData.get('loc'),
                codingType: formData.get('coding-type'),
                preferredTime: formData.get('preferred-time-whitebox'),
                environment: formData.get('environment-whitebox'),
                contact: formData.get('contact-point-whitebox')
            };
        }

        try {
            const response = await fetch('https://ptqtv2-api.appsecure.website/api/v1/submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 
                    'Authorization': 'Bearer cHRxdDo3TGVaLVl4Rk0yQ3VVVW50ZkhETQ==' },
                body: JSON.stringify(formDataObject)
            });

            if (response.ok) {
                alert('Form data submitted successfully');
                document.getElementById('pentest-form').reset();
            } else {
                alert('Failed to submit data');
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    return (
        <div className="bg-gray-100 flex items-center justify-center h-full min-h-screen py-4">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg">
                <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Website Penetration Testing Questionnaire</h2>

                <form id="pentest-form">
                    <div className="mb-4">
                        <label htmlFor="user-name" className="block text-gray-700 font-medium mb-2">
                            กรุณากรอกชื่อบริษัทของท่าน (สามารถใช้ตัวย่อได้)
                        </label>
                        <input
                            type="text"
                            id="user-name"
                            name="user-name"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="test-type" className="block text-gray-700 font-medium mb-2">
                            ต้องการทำ Website Penetration Testing ลักษณะใด?
                        </label>
                        <select
                            id="test-type"
                            name="test-type"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={testType}
                            onChange={handleTestTypeChange}
                        >
                            <option value="" disabled>
                                กรุณาเลือก
                            </option>
                            <option value="blackbox">Black Box - ทดสอบเจาะจากมุมมองของ Hacker ภายนอก</option>
                            <option value="greybox">Grey Box - ทดสอบเจาะจากมุมมองผู้เชี่ยวชาญที่มีข้อมูลบางส่วน</option>
                            <option value="whitebox">White Box - ทดสอบจากมุมมองของผู้เชี่ยวชาญที่มีข้อมูลมาก</option>
                        </select>
                    </div>

                    {/* Black Box Section */}
                    {testType === 'blackbox' && (
                        <div id="blackbox-section" className="mb-6">
                            <label htmlFor="target-domain" className="block text-gray-700 font-medium mb-2">
                                Target Website URL or IP Address
                            </label>
                            <input
                                type="text"
                                id="target-domain"
                                name="target-domain"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="contact-point-blackbox" className="block text-gray-700 font-medium mb-2">
                                Contact point (ข้อมูลสำหรับติดต่อ)
                            </label>
                            <input
                                type="text"
                                id="contact-point-blackbox"
                                name="contact-point-blackbox"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    {/* Grey Box Section */}
                    {testType === 'greybox' && (
                        <div id="greybox-section" className="mb-6">
                            <label htmlFor="website-url-greybox" className="block text-gray-700 font-medium mb-2">
                                Target Website URL or IP Address
                            </label>
                            <input
                                type="text"
                                id="website-url-greybox"
                                name="website-url-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="functions-greybox" className="block text-gray-700 font-medium mb-2">
                                How many webpages or functions in the application?
                            </label>
                            <select
                                id="functions-greybox"
                                name="functions-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="" disabled>
                                    กรุณาเลือก
                                </option>
                                <option value="1-20">1-20 Webpages/Functions</option>
                                <option value="20-50">20-50 Webpages/Functions</option>
                                <option value="50-100">50-100 Webpages/Functions</option>
                            </select>

                            <label htmlFor="target-url-greybox" className="block text-gray-700 font-medium mb-2">
                                Target Sitemap (ข้อมูล Sitemap ของเว็บไซต์ (ถ้ามี))
                            </label>
                            <input
                                type="text"
                                id="target-url-greybox"
                                name="target-url-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="preferred-time-greybox" className="block text-gray-700 font-medium mb-2">
                                Pentest period time?
                            </label>
                            <select
                                id="preferred-time-greybox"
                                name="preferred-time-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Business hour">Business hour</option>
                                <option value="Non-Business hour">Non-Business hour</option>
                                <option value="Weekend">Weekend</option>
                            </select>

                            <label htmlFor="environment-greybox" className="block text-gray-700 font-medium mb-2">
                                Testing Environment?
                            </label>
                            <select
                                id="environment-greybox"
                                name="environment-greybox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Non-Production">Test (UAT, Staging)</option>
                                <option value="Production">Production</option>
                            </select>

                            <label htmlFor="contact-point-greybox" className="block text-gray-700 font-medium mb-2">
                                Contact point (ข้อมูลสำหรับติดต่อ)
                            </label>
                            <input
                                type="text"
                                id="contact-point-greybox"
                                name="contact-point-greybox"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    {/* White Box Section */}
                    {testType === 'whitebox' && (
                        <div id="whitebox-section" className="mb-6">
                            <label htmlFor="website-url-whitebox" className="block text-gray-700 font-medium mb-2">
                                Target Website URL or IP Address
                            </label>
                            <input
                                type="text"
                                id="website-url-whitebox"
                                name="website-url-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="functions-whitebox" className="block text-gray-700 font-medium mb-2">
                                How many webpages or functions in the application?
                            </label>
                            <select
                                id="functions-whitebox"
                                name="functions-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="" disabled>
                                    กรุณาเลือก
                                </option>
                                <option value="1-20">1-20 Webpages/Functions</option>
                                <option value="20-50">20-50 Webpages/Functions</option>
                                <option value="50-100">50-100 Webpages/Functions</option>
                            </select>

                            <label htmlFor="target-url-whitebox" className="block text-gray-700 font-medium mb-2">
                                Target Sitemap (ข้อมูล Sitemap ของเว็บไซต์ (ถ้ามี))
                            </label>
                            <input
                                type="text"
                                id="target-url-whitebox"
                                name="target-url-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="language" className="block text-gray-700 font-medium mb-2">
                                Development Language? (ภาษาที่ใช้ในการพัฒนา)
                            </label>
                            <input
                                type="text"
                                id="language"
                                name="language"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="loc" className="block text-gray-700 font-medium mb-2">
                                How many Line of Code? (จำนวนบรรทัดโค้ด)
                            </label>
                            <input
                                type="text"
                                id="loc"
                                name="loc"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="coding-type" className="block text-gray-700 font-medium mb-2">
                                Backend or Frontend?
                            </label>
                            <select
                                id="coding-type"
                                name="coding-type"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Backend">Backend</option>
                                <option value="Frontend">Frontend</option>
                                <option value="Both">ทั้ง Backend และ Frontend</option>
                            </select>

                            <label htmlFor="preferred-time-whitebox" className="block text-gray-700 font-medium mb-2">
                                Pentest period time?
                            </label>
                            <select
                                id="preferred-time-whitebox"
                                name="preferred-time-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Business hour">Business hour</option>
                                <option value="Non-Business hour">Non-Business hour</option>
                                <option value="Weekend">Weekend</option>
                            </select>

                            <label htmlFor="environment-whitebox" className="block text-gray-700 font-medium mb-2">
                                Testing Environment?
                            </label>
                            <select
                                id="environment-whitebox"
                                name="environment-whitebox"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Non-Production">Test (UAT, Staging)</option>
                                <option value="Production">Production</option>
                            </select>

                            <label htmlFor="contact-point-whitebox" className="block text-gray-700 font-medium mb-2">
                                Contact point (ข้อมูลสำหรับติดต่อ)
                            </label>
                            <input
                                type="text"
                                id="contact-point-whitebox"
                                name="contact-point-whitebox"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    <button
                        type="button"
                        onClick={submitFormData}
                        className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default WebsiteQuestionnaire;
