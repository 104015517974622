import React, { useState } from 'react';

const MobileQuestionnaire = () => {
    const [formType, setFormType] = useState('');
    const [mobilePlatform, setMobilePlatform] = useState('');

    const handleFormTypeChange = (e) => {
        setFormType(e.target.value);
    };

    const handleMobilePlatformChange = (e) => {
        setMobilePlatform(e.target.value);
    };

    const submitFormData = async () => {
        const formDataObject = {
            userName: document.getElementById('user-name').value,
            formType: 'mobile',
            mobilePlatform,
            type: formType
        };

        if (formType === 'BlackBox') {
            formDataObject.appInfo = document.getElementById('blackbox-app-info').value;
            formDataObject.users = document.getElementById('blackbox-users').value;
            formDataObject.contact = document.getElementById('blackbox-contact').value;
            formDataObject.preferredTime = document.getElementById('blackbox-period').value;
            formDataObject.environment = document.getElementById('blackbox-environment').value;
        } else if (formType === 'GreyBox') {
            formDataObject.appInfo = document.getElementById('greybox-app-info').value;
            formDataObject.users = document.getElementById('greybox-users').value;
            formDataObject.roles = document.getElementById('greybox-users-roles').value;
            formDataObject.functions = document.getElementById('greybox-functions').value;
            formDataObject.preferredTime = document.getElementById('greybox-period').value;
            formDataObject.environment = document.getElementById('greybox-environment').value;
            formDataObject.contact = document.getElementById('greybox-contact').value;
        } else if (formType === 'WhiteBox') {
            formDataObject.appInfo = document.getElementById('whitebox-app-info').value;
            formDataObject.users = document.getElementById('whitebox-users').value;
            formDataObject.roles = document.getElementById('whitebox-users-roles').value;
            formDataObject.functions = document.getElementById('whitebox-functions').value;
            formDataObject.language = document.getElementById('whitebox-dev-language').value;
            formDataObject.loc = document.getElementById('whitebox-loc').value;
            formDataObject.apiEndpoint = document.getElementById('whitebox-api-endpoint').value;
            formDataObject.preferredTime = document.getElementById('whitebox-period').value;
            formDataObject.environment = document.getElementById('whitebox-environment').value;
            formDataObject.contact = document.getElementById('whitebox-contact').value;
        }

        try {
            const response = await fetch('https://ptqtv2-api.appsecure.website/api/v1/submit', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 
                    'Authorization': 'Bearer cHRxdDo3TGVaLVl4Rk0yQ3VVVW50ZkhETQ==' },
                body: JSON.stringify(formDataObject)
            });

            if (response.ok) {
                alert('Form data submitted successfully');
                document.getElementById('pentest-form').reset();
            } else {
                alert('Failed to submit data');
            }
        } catch (error) {
            console.error('Error submitting form data:', error);
        }
    };

    return (
        <div className="bg-gray-100 flex items-center justify-center  h-full min-h-screen py-4">
            <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg">
                <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Mobile Penetration Testing Request Form</h2>

                <form id="pentest-form">
                    <div className="mb-4">
                        <label htmlFor="user-name" className="block text-gray-700 font-medium mb-2">
                            กรุณากรอกชื่อบริษัทของท่าน (สามารถใช้ตัวย่อได้)
                        </label>
                        <input
                            type="text"
                            id="user-name"
                            name="user-name"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="mobile-pt" className="block text-gray-700 font-medium mb-2">
                            ต้องการทำ Mobile Penetration Testing บน Platform ใด?
                        </label>
                        <select
                            id="mobile-pt"
                            name="mobile-pt"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={handleMobilePlatformChange}
                        >
                            <option value="" disabled selected>
                                กรุณาเลือก
                            </option>
                            <option value="iOS">iOS - Apple Device Application</option>
                            <option value="Android">Android - Android Device Application</option>
                            <option value="Both">Both - ทั้ง 2 ประเภท (iOS และ Android)</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="web-pt" className="block text-gray-700 font-medium mb-2">
                            ต้องการทำ Mobile Penetration Testing ลักษณะใด?
                        </label>
                        <select
                            id="web-pt"
                            name="web-pt"
                            className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            onChange={handleFormTypeChange}
                        >
                            <option value="" disabled selected>
                                กรุณาเลือก
                            </option>
                            <option value="BlackBox">Black Box - ทดสอบเจาะจากมุมมองของ Hacker ภายนอก</option>
                            <option value="GreyBox">Grey Box - ทดสอบเจาะจากมุมมองผู้เชี่ยวชาญที่มีข้อมูลบางส่วน</option>
                            <option value="WhiteBox">White Box - ทดสอบจากมุมมองของผู้เชี่ยวชาญที่มีข้อมูลมาก</option>
                        </select>
                    </div>

                    {/* Black Box Section */}
                    {formType === 'BlackBox' && (
                        <div id="blackbox-section" className="mb-6">
                            <label htmlFor="blackbox-app-info" className="block text-gray-700 font-medium mb-2">
                                กรุณาอธิบายเกี่ยวกับข้อมูลของ App
                            </label>
                            <textarea
                                id="blackbox-app-info"
                                name="blackbox-app-info"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            ></textarea>

                            <label htmlFor="blackbox-users" className="block text-gray-700 font-medium mb-2">
                                ผู้ใช้งาน Application คือใคร?
                            </label>
                            <input
                                type="text"
                                id="blackbox-users"
                                name="blackbox-users"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="blackbox-contact" className="block text-gray-700 font-medium mb-2">
                                Contact Point
                            </label>
                            <input
                                type="text"
                                id="blackbox-contact"
                                name="blackbox-contact"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="blackbox-period" className="block text-gray-700 font-medium mb-2">
                                Pentest period time?
                            </label>
                            <select
                                id="blackbox-period"
                                name="blackbox-period"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Business hour">Business hour</option>
                                <option value="Non-Business hour">Non-Business hour</option>
                                <option value="Weekend">Weekend</option>
                            </select>

                            <label htmlFor="blackbox-environment" className="block text-gray-700 font-medium mb-2">
                                Testing Environment?
                            </label>
                            <select
                                id="blackbox-environment"
                                name="blackbox-environment"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Non-Production">Test (UAT, Staging)</option>
                                <option value="Production">Production</option>
                            </select>
                        </div>
                    )}

                    {/* Grey Box Section */}
                    {formType === 'GreyBox' && (
                        <div id="greybox-section" className="mb-6">
                            <label htmlFor="greybox-app-info" className="block text-gray-700 font-medium mb-2">
                                กรุณาอธิบายเกี่ยวกับข้อมูลของ App
                            </label>
                            <textarea
                                id="greybox-app-info"
                                name="greybox-app-info"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            ></textarea>

                            <label htmlFor="greybox-users" className="block text-gray-700 font-medium mb-2">
                                ผู้ใช้งาน Application คือใคร?
                            </label>
                            <input
                                type="text"
                                id="greybox-users"
                                name="greybox-users"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="greybox-users-roles" className="block text-gray-700 font-medium mb-2">
                                How many Users/Roles in the application?
                            </label>
                            <select
                                id="greybox-users-roles"
                                name="greybox-users-roles"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="" disabled selected>
                                    กรุณาเลือก
                                </option>
                                <option value="1">1 User / 1 Role</option>
                                <option value="2">2 Users / 2 Roles</option>
                                <option value="5">5 Users / 5 Roles</option>
                                <option value="10">10 Users / 10 Roles</option>
                            </select>

                            <label htmlFor="greybox-functions" className="block text-gray-700 font-medium mb-2">
                                How many functions in the application?
                            </label>
                            <select
                                id="greybox-functions"
                                name="greybox-functions"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="" disabled selected>
                                    กรุณาเลือก
                                </option>
                                <option value="1-20">1-20 Functions</option>
                                <option value="20-50">20-50 Functions</option>
                                <option value="50-100">50-100 Functions</option>
                            </select>

                            <label htmlFor="greybox-period" className="block text-gray-700 font-medium mb-2">
                                Pentest period time?
                            </label>
                            <select
                                id="greybox-period"
                                name="greybox-period"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Business hour">Business hour</option>
                                <option value="Non-Business hour">Non-Business hour</option>
                                <option value="Weekend">Weekend</option>
                            </select>

                            <label htmlFor="greybox-environment" className="block text-gray-700 font-medium mb-2">
                                Testing Environment?
                            </label>
                            <select
                                id="greybox-environment"
                                name="greybox-environment"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Non-Production">Test (UAT, Staging)</option>
                                <option value="Production">Production</option>
                            </select>

                            <label htmlFor="greybox-contact" className="block text-gray-700 font-medium mb-2">
                                Contact Point
                            </label>
                            <input
                                type="text"
                                id="greybox-contact"
                                name="greybox-contact"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    {/* White Box Section */}
                    {formType === 'WhiteBox' && (
                        <div id="whitebox-section" className="mb-6">
                            <label htmlFor="whitebox-app-info" className="block text-gray-700 font-medium mb-2">
                                กรุณาอธิบายเกี่ยวกับข้อมูลของ App
                            </label>
                            <textarea
                                id="whitebox-app-info"
                                name="whitebox-app-info"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            ></textarea>

                            <label htmlFor="whitebox-users" className="block text-gray-700 font-medium mb-2">
                                ผู้ใช้งาน Application คือใคร?
                            </label>
                            <input
                                type="text"
                                id="whitebox-users"
                                name="whitebox-users"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="whitebox-users-roles" className="block text-gray-700 font-medium mb-2">
                                How many Users/Roles in the application?
                            </label>
                            <select
                                id="whitebox-users-roles"
                                name="whitebox-users-roles"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="" disabled selected>
                                    กรุณาเลือก
                                </option>
                                <option value="1">1 User / 1 Role</option>
                                <option value="2">2 Users / 2 Roles</option>
                                <option value="5">5 Users / 5 Roles</option>
                                <option value="10">10 Users / 10 Roles</option>
                            </select>

                            <label htmlFor="whitebox-functions" className="block text-gray-700 font-medium mb-2">
                                How many functions in the application?
                            </label>
                            <select
                                id="whitebox-functions"
                                name="whitebox-functions"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="" disabled selected>
                                    กรุณาเลือก
                                </option>
                                <option value="1-20">1-20 Functions</option>
                                <option value="20-50">20-50 Functions</option>
                                <option value="50-100">50-100 Functions</option>
                            </select>

                            <label htmlFor="whitebox-dev-language" className="block text-gray-700 font-medium mb-2">
                                Development Language?
                            </label>
                            <input
                                type="text"
                                id="whitebox-dev-language"
                                name="whitebox-dev-language"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="whitebox-loc" className="block text-gray-700 font-medium mb-2">
                                How many Line of Code?
                            </label>
                            <input
                                type="text"
                                id="whitebox-loc"
                                name="whitebox-loc"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="whitebox-api-endpoint" className="block text-gray-700 font-medium mb-2">
                                How many API Endpoint?
                            </label>
                            <input
                                type="text"
                                id="whitebox-api-endpoint"
                                name="whitebox-api-endpoint"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />

                            <label htmlFor="whitebox-period" className="block text-gray-700 font-medium mb-2">
                                Pentest period time?
                            </label>
                            <select
                                id="whitebox-period"
                                name="whitebox-period"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Business hour">Business hour</option>
                                <option value="Non-Business hour">Non-Business hour</option>
                                <option value="Weekend">Weekend</option>
                            </select>

                            <label htmlFor="whitebox-environment" className="block text-gray-700 font-medium mb-2">
                                Testing Environment?
                            </label>
                            <select
                                id="whitebox-environment"
                                name="whitebox-environment"
                                className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            >
                                <option value="Non-Production">Test (UAT, Staging)</option>
                                <option value="Production">Production</option>
                            </select>

                            <label htmlFor="whitebox-contact" className="block text-gray-700 font-medium mb-2">
                                Contact Point
                            </label>
                            <input
                                type="text"
                                id="whitebox-contact"
                                name="whitebox-contact"
                                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            />
                        </div>
                    )}

                    <button
                        type="button"
                        onClick={submitFormData}
                        className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 transition duration-200"
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

export default MobileQuestionnaire;