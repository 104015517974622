import React from 'react';

const LandingPage = () => {
    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
            <div className="bg-white p-10 rounded-xl shadow-lg w-full max-w-md">
                <h1 className="text-4xl font-extrabold text-center text-gray-800 mb-8">
                    Penetration Testing Services
                </h1>
                <p className="text-lg text-center text-gray-600 mb-6">
                    กรุณาเลือกประเภทของบริการที่ท่านต้องการให้ข้อมูล:
                </p>
                <div className="space-y-6">
                    <a
                        href="/website-questionnaire"
                        className="block text-center bg-gradient-to-r from-blue-600 to-indigo-600 text-white font-semibold text-xl p-4 rounded-lg shadow-lg hover:bg-gradient-to-r hover:from-indigo-600 hover:to-purple-600 transform hover:-translate-y-1 transition-all duration-300"
                    >
                        Website Penetration Testing
                    </a>
                    <a
                        href="/mobile-questionnaire"
                        className="block text-center bg-gradient-to-r from-green-500 to-teal-500 text-white font-semibold text-xl p-4 rounded-lg shadow-lg hover:bg-gradient-to-r hover:from-teal-500 hover:to-green-500 transform hover:-translate-y-1 transition-all duration-300"
                    >
                        Mobile Penetration Testing
                    </a>
                    <a
                        href="/infra-questionnaire"
                        className="block text-center bg-gradient-to-r from-red-500 to-pink-500 text-white font-semibold text-xl p-4 rounded-lg shadow-lg hover:bg-gradient-to-r hover:from-pink-500 hover:to-red-500 transform hover:-translate-y-1 transition-all duration-300"
                    >
                        Infrastructure Penetration Testing
                    </a>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;